export const achievementData = {
  bio: "Putting quantified achievements on a resume is great, but I just don’t work with hard numbers. I have no data to support the quality of my work. If you have lots of relevant experience, the heading statement of your resume will be a summary.",
  achievements: [
    {
      id: 1,
      title: "Google Cloud certification",
      details:
        "Google Cloud certifications validate your expertise and showcase your ability to transform businesses with Google Cloud technology.",
      date: "Aug 20, 2019",
      field: "Automation",
      image:
        "https://images.unsplash.com/photo-1573141597928-403fcee0e056?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80",
    },
    {
      id: 2,
      title: "AWS Certification",
      details:
        "Validate technical skills and cloud expertise to grow your career and business.",
      date: "Aug 20, 2019",
      field: "Automation",
      image:
        "https://images.unsplash.com/photo-1523474253046-8cd2748b5fd2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    },
    {
      id: 3,
      title: "Professional Data Engineer Certification",
      details:
        "Professional Data Engineers enable data-driven decision making by collecting, transforming, and publishing data.",
      date: "Aug 20, 2019",
      field: "Automation",
      image:
        "https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    },
  ],
};

// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
