export const educationData = [
  {
    id: 1,
    institution: "Sri Lakshmi Ammal Engineering College, Chennai",
    course: "Bachelor of Engineering - ECE",
    startYear: "2017",
    endYear: "2021",
  },
  {
    id: 2,
    institution: "Government Higher Scondary School, Melarani",
    course: "HSC",
    startYear: "2015",
    endYear: "2017",
  },
  {
    id: 3,
    institution: "Government Higher Scondary School, Melarani",
    course: "SSLC",
    startYear: "2014",
    endYear: "2015",
  },
];
