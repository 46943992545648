import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Fade from "react-reveal/Fade";

import { AiOutlineFolder } from "react-icons/ai";
import "./Achievement.css";
import { connect } from "react-redux";

type IAchievementCardProps = Partial<ReturnType<typeof mapStateToProps>> & {
  id;
  title;
  details;
  date;
  field;
  image;
};

function AchievementCard(props: IAchievementCardProps) {
  const { theme, id, title, details, date, field, image } = props;

  const useStyles = makeStyles((t) => ({
    achievementCard: {
      backgroundColor: theme.primary30,
      "&:hover": {
        backgroundColor: theme.primary50,
      },
    },
  }));

  const classes = useStyles();
  return (
    <Fade bottom>
      <div key={id} className={`achievement-card ${classes.achievementCard}`}>
        <div className="achievecard-content">
          <div className="achievecard-details1">
            <h2 style={{ color: theme.tertiary }}>{title}</h2>
            <p style={{ color: theme.tertiary80 }}>{details}</p>
          </div>
          <div
            className="achievecard-details2"
            style={{ color: theme.primary }}
          >
            <h5>{date}</h5>
            <div className="achievecard-field">
              <AiOutlineFolder />
              <h5>{field}</h5>
            </div>
          </div>
        </div>
        <div className="achievecard-imgcontainer">
          <img src={image} alt="" />
        </div>
      </div>
    </Fade>
  );
}

const mapStateToProps = (state) => ({
  theme: state.rootState.theme,
});

export default connect(mapStateToProps)(AchievementCard);
