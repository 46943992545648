export const experienceData = [
  {
    id: 1,
    company: "Lumel Technologies",
    jobTitle: "Senior Product Developer",
    startYear: "JAN 2024",
    endYear: "Present",
  },
  {
    id: 2,
    company: "Lumel Technologies",
    jobTitle: "Product Developer",
    startYear: "JAN 2023",
    endYear: "DEC 2023",
  },
  {
    id: 3,
    company: "Lumel Technologies",
    jobTitle: "Associate Product Developer",
    startYear: "JAN 2022",
    endYear: "DEC 2022",
  },
  {
    id: 4,
    company: "Lumel Technologies",
    jobTitle: "Product Developer Trainee",
    startYear: "JUNE 2021",
    endYear: "DEC 2021",
  },
];
