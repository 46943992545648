/* eslint-disable */
import deepa from "../images/deepa.png";
import boy2 from "../assets/svg/testimonials/boy2.svg";
import aalfie from "../images/aalfie.png";
import murali from "../images/murali.png";
import sridher from "../images/sridher.png";

export const testimonialsData = [
  // {
  //   id: 1,
  //   name: "Deepa V",
  //   title: "Placement expert",
  //   text: "I have never seen any person like him. He is an inspiration for many people. It's very rare to see people who learn by themself, he is having that special talent.",
  //   image: deepa,
  // },
  {
    id: 1,
    name: "Jayasridher B",
    title: "Graduate Engineer",
    text: "He is a consistent hard worker who never forget to upgrade himself. His continuous learning and efforts are always made him special",
    image: sridher,
  },
  {
    id: 2,
    name: "Murali M",
    title: "Associate Software Engineer",
    text: "Annamalai is a comman man with uncomman things. he have good problem solving and analytical skills. He will not only grow but also make others grow.",
    image: murali,
  },
  {
    id: 3,
    name: "Arrokiya Raj",
    title: "Founder of Entra 3D",
    text: "Annamalai is the intellectual person who taugh me the correct way. When I'm with him, he always responds my queries and gives me a space and time",
    image: aalfie,
  },
  {
    id: 4,
    name: "Satyam Tripathy",
    title: "Software Developer",
    text: "he has strong knowledge of advanced programming languages.he always gives his best for their work and he always doing something interesting",
    image: boy2,
  },
];
