export const socialsData = {
  github: "https://github.com/annshiv",
  //   facebook: "https://www.facebook.com/",
  linkedIn: "https://www.linkedin.com/in/annamalai923/",
  instagram: "https://www.instagram.com/craziest_king_/",
  //   codepen: "https://codepen.io/",
  twitter: "https://twitter.com/Annamalai345",
  reddit: "https://www.reddit.com/user/annshiv",
  blogger: "https://annshiv.blogspot.com/",
  medium: "https://medium.com/@annamalaipalani11",
  stackOverflow: "https://stackoverflow.com/users/13757016/annshiv",
  youtube: "https://www.youtube.com/channel/UCVgcm6xK8WEZNwtLzpxfL-w/featured",
};
