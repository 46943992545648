import resume from "../assets/pdf/resume.pdf";
import profile from "../assets/png/profile.png";

export const headerData = {
  name: "Annshiv",
  title: "Software Developer",
  desciption:
    "I like pressing buttons on my keyboard untill I make something that amazes me. Seeing myself in internet was a big childhood dream of me. Now that dream comes true. I always wonder to say 'it all starts with a dream'.        ",
  // desciption:
  //   "There wasn't a bird in the sky, but that was not what caught her attention. It was the clouds. The deep green that isn't the color of clouds, but came with these. She knew what was coming and she hoped she was prepared. ",
  image: profile,
  resumePdf: resume,
};
